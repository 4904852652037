<template>
  <div class="daily-info" v-if="model">
    <div class="item item-icon">
      <img class="effect3" :src="imagePath" />
    </div>
    <div class="item clr-orange shadow">
      <strong>{{ model.weatherDay }}</strong>
      <span>{{ model.weatherValue }}°</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "weather1",
  props: ["data"],
  computed: {
    model() {
      return this.$store.state.weather;
    },
    imagePath() {
      return `/images/weather/${this.model.weatherIcon}`;
    }
  }
};
</script>

<style scoped></style>
